import { useState } from 'react'

const Tournament = () => {
  const [participants, setParticipants] = useState([])
  const [started, setStarted] = useState(false)
  const [participantNames, setParticipantNames] = useState('')

  const startTournament = (e) => {
    e.preventDefault()
    const names = participantNames.split(/\r?\n/).sort()
    setParticipants(names.map((name) => { return { name, wins: 0 } }))
    setStarted(true)
  }

  const addWin = (index) => {
    const currentParticipants = [...participants]
    currentParticipants[index].wins += 1
    setParticipants(currentParticipants)
  }

  const removeWin = (index) => {
    const currentParticipants = [...participants]
    if (currentParticipants[index].wins > 0) {
      currentParticipants[index].wins -= 1
      setParticipants(currentParticipants)
    }
  }

  return (
    <div className='mt-2'>
      <h1 className='text-center mb-5'>Tournament</h1>

      {started && (
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Victorias</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                participants.map((participant, i) => (
                  <tr key={i}>
                    <td>{ (i + 1) }</td>
                    <td>{ participant.name }</td>
                    <td>{ participant.wins }</td>
                    <td>
                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-primary" onClick={() => addWin(i)}>
                          <span className="material-symbols-outlined">add</span>
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => removeWin(i)} disabled={participant.wins <= 0}>
                          <span className="material-symbols-outlined">remove</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )}

      {!started && (
        <form onSubmit={startTournament}>
          <div className='mb-3'>
            <label htmlFor='participant-names'>Nombres de los Participantes</label>
            <textarea id='participant-names' className='form-control' value={participantNames} onChange={(e) => setParticipantNames(e.target.value)} rows="8" required ></textarea>
          </div>

          <div className='text-center'>
            <button type="submit" className='btn btn-primary'>Agregar Participantes</button>
          </div>
        </form>
      )}

    </div>
  )
}

export default Tournament
