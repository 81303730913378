import { useState } from 'react'
import movies from '../../../data/movies.json'

const MovieFortune = () => {
  const [currentMovie, setCurrentMovie] = useState('')

  const handleClick = () => {
    const selectedIndex = Math.floor(Math.random() * movies.length)
    setCurrentMovie(movies[selectedIndex])
  }

  return (
    <div className='text-center mt-2'>
      <h1 className='mb-5'>Movie Fortune</h1>

      <h3 className='text-primary'>Nombre de la Pelicula</h3>
      <h2 className='text-danger text-capitalize'>{ currentMovie.name }</h2>

      <button type="button" className='btn btn-primary mt-5' onClick={handleClick}>Seleccionar Pelicula</button>
    </div>
  )
}

export default MovieFortune
