import './App.scss'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { routes } from './routes'
import NotFound from './views/pages/NotFound'
import Topbar from './views/layouts/Topbar'

const App = () => {
  return (
    <Router>
      <Topbar />
      <div className='container'>
        <Switch>
          {routes.map(({ path, exact, Component }) => (
            <Route key={path} path={path} element={<Component />} exact={exact} />
          ))}

          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
