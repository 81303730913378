import Home from './views/pages/Home'
import MovieFortune from './views/pages/games/MovieFortune'
import Tournament from './views/pages/games/Tournament'

const routes = [
  { path: '/', Component: Home, exact: true },
  { path: '/movie-fortune', Component: MovieFortune, exact: true },
  { path: '/tournament', Component: Tournament, exact: true }
]

export { routes }
