import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='text-center'>
      <h1 className='mb-5'>Home</h1>

      <div className='d-flex flex-column align-items-center'>
        <Link to='/movie-fortune' className='btn btn-primary mb-3'>Movie Fortune</Link>
        <Link to='/tournament' className='btn btn-primary'>Tournament</Link>
      </div>
    </div>
  )
}

export default Home
